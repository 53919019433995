/* ============================================================================
   Component: App Icon
   ========================================================================= */

.app-icon {
	margin: 0;
	text-indent: 100%;
	overflow: hidden;
	white-space: nowrap;

	&--list {
		border-radius: 4px;
		display: inline-block;
		vertical-align: middle;
		width: 100%;
		max-width: 135px;
		height: 135px;
		max-height: 100%;

		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		background-color: #f1f2f6;
	}

	&--detail {
		border-radius: 4px;
		display: inline-block;
		vertical-align: middle;
		width: 100%;
		max-width: 100%;
		height: 135px;
		max-height: 100%;

		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		background-color: #f1f2f6;
	}

	&--menubar {
		display: inline-block;
		vertical-align: middle;
		width: 100%;
		max-width: 100%;
		height: 100%;
		max-height: 100%;

		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
	}

	&--placeholder {
		background-image: url('#{$image-path}/placeholder/app-placeholder.jpg');
	}

	&--demo {
		background-image: url('#{$image-path}/placeholder/app-demo-placeholder.jpg');
	}
}