/* ============================================================================
   Page: Catalog Overview
   ========================================================================= */

.catalog-overview {
	.panel {
		min-height: 333px;
		padding: 30px 9%;
		&:hover {
			box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
			.headline a {
				color: $color-primary-hover;
			}
			.image {
				opacity: 0.9;
				@include single-transition(opacity);
			}
		}
		.headline {
			color: $color-primary;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			a:hover {
				text-decoration: none;
				color: $color-primary-hover;
			}
		}
		.description {
			max-height: 48px;
			overflow: hidden;
		}
		div.image {
			height: 135px;
			line-height: 135px;
		}
	}
}

.catalog-overview-wrapper {
	.massDataOutermostShell {
		@extend .row;
	}
	.sworDes1 {
		text-align: center;
		font-size: rem-calc(24px);
		padding: rem-calc(50px) rem-calc(20px);
		border: 0;
		background: none;
	}
}

.catalog-overview {
	.massDataOuterShell {
		padding: 0;
		margin: 0;
	}
}

.el_catalog-overview {
	.fields {
		padding-right: $grid-gutter-pixel;
		padding-left: $grid-gutter-pixel;
	}
}