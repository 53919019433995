/* ============================================================================
   Component: App-Panel
   ========================================================================= */

.apppanelRow {
	padding-bottom: emCalc(40px);
}

.apppanel {
	.apppanel-app-wrapper {
		@extend .clearfix;
		&:hover .apppanel-name {
			text-decoration: underline;
		}

		&:hover .apppanel-icon {
			opacity: 0.8;
		}
	}

	.apppanel-name {
		display: table-cell;
		font-size: emCalc(24px);
		line-height: emCalc(30px, 24px);
		text-align: left;
		vertical-align: middle;
		white-space: normal;
		a {
			display: block;
			margin-left: emCalc(15px, 24px);
		}
	}

	.apppanel-description {
		font-size: emCalc(14px);
		line-height: emCalc(21px, 14px);
		margin-top: emCalc(15px, 14px);
	}

	.apppanel-icon {
		display: table-cell;
		height: auto;
		width: 90px;
		max-width: 90px;
		overflow: hidden;
		border-radius: 3px;
	}

	.apppanel-specialimage {
		margin-bottom: emCalc(20px);
	}
}