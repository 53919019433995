/* ============================================================================
   Page: MyLivingApps Publish
   ========================================================================= */

.mylivingapps-publish {
	h1, h2 {
		text-align: left;
		padding: 0;
		margin: 0;
	}
	h1 {
		font-size: rem-calc(30px);
	}
	h2 {
		font-size: rem-calc(22px);
	}
	&.custom {
		label {
			padding-bottom: rem-calc(10px);
		}
		.custom {
			width: 22px;
			height: 22px;
			&.radio.checked:before {
				width: 18px;
				height: 18px;
			}
		}
	}
	.fileupload {
		padding: rem-calc(10px);
		background: $color-athens-gray;
		border-radius: 4px;
	}
	.formFieldDescription {
		font-size: rem-calc(14px);
	}
}