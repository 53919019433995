/* ============================================================================
   Base: Forms
   ========================================================================= */

/**
 * Form Label
 */
$label-color: $color-grey !default;
$label-font-size: rem-calc(16px) !default;
$label-font-weight: $semi-bold !default;
$label-padding: 0 0 emCalc(10px) !default;
$label-margin: null !default;

/**
 * Input
 */
$input-color: $color-dark !default;
$input-font-size: rem-calc(16px) !default;
$input-width: 100% !default;
$input-padding: emCalc(8px) emCalc(12px) !default;
$input-margin: null !default;
$input-border: 2px solid $color-mercury !default;
$input-focus-border-color: $color-primary !default;
$input-hover-border-color: $color-mercury-dark !default;
$input-placeholder-color: $color-gray-chateau !default;
$input-file-font-size: rem-calc(16px) !default;
$input-file-padding: emCalc(8px, 16px) !default;

/**
 * Textarea
 */
$textarea-color: null !default;
$textarea-font-size: rem-calc(16px) !default;
$textarea-line-height: emCalc(22px) !default;
$textarea-width: null !default;
$textarea-padding: emCalc(8px) emCalc(12px) !default;
$textarea-margin: null !default;
$textarea-border: null !default;

/**
 * Select
 */
$select-color: $color-dark !default;
$select-font-size: rem-calc(16px) !default;
$select-width: 100% !default;
$select-padding: emCalc(8px) emCalc(12px) !default;
$select-margin: null !default;
$select-border: 2px solid $color-mercury !default;
$select-focus-border-color: $color-primary !default;
$select-hover-border-color: $color-mercury-dark !default;

@mixin custom-form-select() {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	font-size: $select-font-size;
	line-height: normal;
	color: $select-color;
	width: $select-width;
	padding: $select-padding;
	margin: $select-margin;
	background-color: $custom-dropdown-background;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+");
	background-position: 100% center;
	background-repeat: no-repeat;
	border: $select-border;
	border-radius: 0;
	&:hover {
		border-color: $select-hover-border-color;
	}
	&:focus {
		border-color: $select-focus-border-color;
		outline: 0;
	}

	&::-ms-expand {
		display: none;
	}
}

/**
 * Custom Checkbox and Radio Inputs
 */
$custom-form-border-color: $color-iron !default;
$custom-form-border-size: 2px !default;
$custom-form-bg: $color-light !default;
$custom-form-input-size: 16px !default;
$custom-form-check-color: $color-primary !default;
$custom-form-check-size: 16px !default;
$custom-form-radio-size: 12px !default;
$custom-form-checkbox-radius: 0 !default;

@mixin custom-form-input($radius:0px, $mark-size:0px) {
	margin-right: 8px;
	border-radius: $radius;
	$custom-form-input-padding: (($custom-form-input-size - $mark-size) / 2) - $custom-form-border-size;
	@if $custom-form-input-padding >= 0 {
		padding: $custom-form-input-padding;
	} @else {
		padding: 0;
	}
}

/**
 * Custom Dropdown
 */
$custom-dropdown-border: 2px solid $color-mercury !default;
$custom-dropdown-background: $color-light !default;
$custom-dropdown-selector-border: 2px solid $color-mercury !default;

.custom {
	label{
		display: block;
		color: $label-color;
		font-size: $label-font-size;
		font-weight: $label-font-weight;
		padding: $label-padding;
		margin: $label-margin;
	}

	input[type="text"],
	input[type="password"],
	input[type="date"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="month"],
	input[type="week"],
	input[type="email"],
	input[type="number"],
	input[type="search"],
	input[type="tel"],
	input[type="time"],
	input[type="url"],
	input[type="color"],
	textarea {
		color: $input-color;
		font-size: $input-font-size;
		width: $input-width;
		padding: $input-padding;
		margin: $input-margin;
		border: $input-border;
		-webkit-appearance: none;
		-moz-appearance: none;
		border-radius: 0;
		@include single-transition(border);
		&:hover {
			border-color: $input-hover-border-color;
		}
		&:focus {
			border-color: $input-focus-border-color;
			outline: none;
		}

		&::placeholder {
			color: $input-placeholder-color !important;
			opacity: 1;
		}
	}

	input[type="file"] {
		font-size: $input-file-font-size;
		padding-bottom: $input-file-padding;
	}

	textarea {
		color: $textarea-color;
		font-size: $textarea-font-size;
		line-height: $textarea-line-height;
		width: $textarea-width;
		padding: $textarea-padding;
		margin: $textarea-margin;
		border: $textarea-border;
		resize: vertical;
		vertical-align: top;
	}

	.abideError {
		display: none;
	}

	.error .abideError {
		display: block;
	}

	.has-input-icon-right {
		position: relative;
	}

	.input-button {
		position: absolute;
		top: 0;
		right: 0;
	}

	.input-icon-right {
		top: 11px;
		right: 11px;
		padding: 0;
		margin: 0;
		background: none;
		border: 0;
		color: $color-iron-dark;
		box-shadow: none;
		@include single-transition(all);
	}
}

/* Custom Checkbox and Radio Inputs */
.custom {
	.hidden-field {
		margin-left: -99999px;
		position: absolute;
	}

	.custom {
		display: inline-block;
		width: $custom-form-input-size;
		height: $custom-form-input-size;
		position: relative;
		top:-1px; /* fix centering issue */
		vertical-align: middle;
		border: solid $custom-form-border-size $custom-form-border-color;
		background: $custom-form-bg;

		&.checkbox {
			@include custom-form-input($radius:$custom-form-checkbox-radius, $mark-size:$custom-form-check-size);
		}

		&.radio {
			@include custom-form-input($radius:1000px, $mark-size:$custom-form-radio-size);
		}

		&.checkbox {
			&:before {
				content: "";
				display: block;
				font-size: $custom-form-check-size;
				color: $custom-form-bg;
			}
		}

		&.radio.checked {
			&:before {
				content: "";
				display: block;
				width: $custom-form-radio-size;
				height: $custom-form-radio-size;
				border-radius: 50%;
				background: $custom-form-check-color;
				position: relative;
			}
		}

		&.checkbox.checked {
			&:before {
				content: "";
				background: $custom-form-check-color;
				width: $custom-form-check-size - ($custom-form-border-size*2);
				height: $custom-form-check-size - ($custom-form-border-size*2);
				position: relative;
			}
		}
	}
}

.custom {
	select {
		@include custom-form-select;
	}
}

/* Custom Foundation Select Options and Dropdowns */
.custom {
	/* Custom input, disabled */ }
	.custom .custom.dropdown {
		display: block;
		position: relative;
		top: 0;
		height: emCalc(44px, 20px);
		margin-bottom: emCalc(5px, 20px);
		margin-top: 0;
		padding: 0;
		width: 100%;
		background: $custom-dropdown-background;
		border: $custom-dropdown-border;
		box-shadow: none;
		font-size: emCalc(20px);
		vertical-align: top; }
		.custom .custom.dropdown:hover .selector {
			@include single-transition(all);
			background: #67737a; }
		.custom .custom.dropdown ul {
			overflow-y: auto;
			max-height: 200px; }
		.custom .custom.dropdown .current {
			cursor: default;
			white-space: nowrap;
			line-height: emCalc(40px, 20px);
			color: $color-dark;
			text-decoration: none;
			overflow: hidden;
			display: block;
			margin-left: emCalc(20px, 20px);
			margin-right: 2.3125em; }
		.custom .custom.dropdown .selector {
			cursor: default;
			position: absolute;
			width: emCalc(40px, 20px);
			height: emCalc(40px, 20px);
			background: #808c94;
			border-left: $custom-dropdown-selector-border;
			display: block;
			right: 0;
			top: 0; }
			.custom .custom.dropdown .selector:after {
				display: block;
				color: #fff;
				font-size: 23px;
				width: 0;
				height: 0;
				position: absolute;
				left: emCalc(8px, 23px);
				top: 50%;
				margin-top: -11px;
				@extend .font-icon:before;
				@extend .font-icon-down:before; }
		.custom .custom.dropdown .disabled {
			color: #888888; }
			.custom .custom.dropdown .disabled:hover {
				background: transparent;
				color: #888888; }
				.custom .custom.dropdown .disabled:hover:after {
					display: none; }
		.custom .custom.dropdown.open .selector {
			@include single-transition(all);
			background: #67737a; }
		.custom .custom.dropdown.open ul {
			display: block;
			z-index: 10;
			min-width: 100%;
			box-sizing: content-box; }
		.custom .custom.dropdown.small {
			max-width: 134px; }
		.custom .custom.dropdown.medium {
			max-width: 254px; }
		.custom .custom.dropdown.large {
			max-width: 434px; }
		.custom .custom.dropdown.expand {
			width: 100% !important; }
		.custom .custom.dropdown.open.small ul {
			min-width: 134px;
			box-sizing: border-box; }
		.custom .custom.dropdown.open.medium ul {
			min-width: 254px;
			box-sizing: border-box; }
		.custom .custom.dropdown.open.large ul {
			min-width: 434px;
			box-sizing: border-box; }
	.custom .custom.dropdown ul {
		position: absolute;
		width: auto;
		display: none;
		margin: 0;
		left: -1px;
		top: auto;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
		margin: 0;
		padding: 0;
		background: white;
		border: solid 1px #cccccc;
		font-size: 16px; }
		.custom .custom.dropdown ul li {
			color: #555555;
			font-size: 0.875em;
			cursor: default;
			padding-top: 0.25em;
			padding-bottom: 0.25em;
			padding-left: 0.375em;
			padding-right: 2.375em;
			min-height: 1.7em;
			line-height: 1.7em;
			margin: 0;
			white-space: nowrap;
			list-style: none; }
			.custom .custom.dropdown ul li.selected {
				background: #eeeeee;
				color: black; }
			.custom .custom.dropdown ul li:hover {
				background-color: #e4e4e4;
				color: black; }
			.custom .custom.dropdown ul li.selected:hover {
				background: #eeeeee;
				cursor: default;
				color: black; }
		.custom .custom.dropdown ul.show {
			display: block; }
	.custom .custom.disabled {
		background: #dddddd; }

.formError, .abideError, .field-error {
	display: block;
	color: $color-red;
	font-weight: $semi-bold;
	padding-top: emCalc(10px);
}

.formHeader {
	font-size: emCalc(30px);
	font-weight: $extra-light;
	line-height: emCalc(36px, 30px);
	color: #666;
	padding: emCalc(5px, 30px) 0 emCalc(10px, 30px);
	margin-top: emCalc(10px, 30px);
	border-bottom: 1px solid $color-border-grey;
}

.notifyRow {
	border: 1px solid $color-border-grey;
	border-left: 0;
	border-right: 0;
	padding: emCalc(13px) 0;
	margin-top: -1px;
	background: $color-light-greybox;
	.message {
		font-size: emCalc(36px);
		font-weight: $extra-light;
		line-height: emCalc(54px, 46px);
		text-align: center;
	}
}

.successRow {
	display: none;
	color: $color-greenbox-font;
	background: $color-greenbox-bg;
}

.errorRow {
	color: $color-redbox;
	background: $color-redbox-bg;
	display: none;

	.errorMessage {
		padding: 1em;
		border: 1px solid $color-redbox;
	}
}