/* ============================================================================
   Page: My LivingApps Data Configuration
   ========================================================================= */

.mylivingapps-data-configuration {
	h1, h2 {
		text-align: left;
		padding: 0;
		margin: 0;
	}
	h1 {
		font-size: rem-calc(30px);
	}
	h2 {
		font-size: rem-calc(22px);
		margin-bottom: rem-calc(10px);
	}
}