/* ============================================================================
   Component: Head Overlay
   ========================================================================= */

$head-overlay-hint-color: $color-cutty-sark !default;
$head-overlay-option-label-color: $color-cutty-sark !default;
$head-overlay-demo-background: #c7cdd0 !default;

.head-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10020;
	.title {
		line-height: rem-calc(57px);
		margin: 0 0 0 rem-calc(8px);
		.hint {
			display: inline-block;
			line-height: 20px;
			margin-left: 10px;
			color: $head-overlay-hint-color;
		}
		.font-icon:before {
			font-size: 20px;
		}
	}
	.options {
		padding: 0;
		margin: 0;
		&.right {
			.option {
				float: right;
				margin-right: rem-calc(16px);
			}
		}
		.option {
			padding: 17px 0 16px;
			label {
				display: inline-block;
				font-size: rem-calc(18px);
				padding: 0 0 0 rem-calc(10px);
				color: $head-overlay-option-label-color;
			}
			.custom.checkbox {
				width: 18px;
				height: 18px;
				border: 0;
				top: -2px;
				&:before {
					width: 12px;
					height: 12px;
					margin: 3px;
				}
			}
		}
	}
	.button {
		margin: 0;
		padding-top: 8px;
		padding-bottom: 8px;
	}
	&.head-overlay-demo {
		background: $head-overlay-demo-background;
	}
}