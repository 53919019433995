/* ============================================================================
   Base: Fonts
   ========================================================================= */

@font-face {
	font-family: 'Source Sans Pro';
	font-weight: 200;
	font-style: normal;
	src: url('../fonts/sourcesanspro-extralight.eot');
	src: local('Source Sans Pro ExtraLight'),
		 local('SourceSansPro-ExtraLight'),
		 url('../fonts/sourcesanspro-extralight.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/sourcesanspro-extralight.woff2') format('woff2'),
		 url('../fonts/sourcesanspro-extralight.woff') format('woff'),
		 url('../fonts/sourcesanspro-extralight.ttf') format('truetype'),
		 url('../fonts/sourcesanspro-extralight.svg#source_sans_prolight') format('svg');
}

@font-face {
	font-family: 'Source Sans Pro';
	font-weight: 400;
	font-style: normal;
	src: url('../fonts/sourcesanspro-regular.eot');
	src: local('Source Sans Pro Regular'),
		 local('SourceSansPro-Regular'),
		 url('../fonts/sourcesanspro-regular.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/sourcesanspro-regular.woff2') format('woff2'),
		 url('../fonts/sourcesanspro-regular.woff') format('woff'),
		 url('../fonts/sourcesanspro-regular.ttf') format('truetype'),
		 url('../fonts/sourcesanspro-regular.svg#source_sans_prolight') format('svg');
}

@font-face {
	font-family: 'Source Sans Pro';
	font-weight: 600;
	font-style: normal;
	src: url('../fonts/sourcesanspro-semibold.eot');
	src: local('Source Sans Pro ExtrSemiBoldaLight'),
		 local('SourceSansPro-SemiBold'),
		 url('../fonts/sourcesanspro-semibold.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/sourcesanspro-semibold.woff2') format('woff2'),
		 url('../fonts/sourcesanspro-semibold.woff') format('woff'),
		 url('../fonts/sourcesanspro-semibold.ttf') format('truetype'),
		 url('../fonts/sourcesanspro-semibold.svg#source_sans_prolight') format('svg');
}