/* ============================================================================
   Base: Utils
   ========================================================================= */

.hiddenfields {
	display: none;
}

.border-right {
	border-right: 1px solid $color-border-grey;
}

%background-light {
	background-color: $color-light;
}

.background-light {
	@extend %background-light;
}

.headline-account {
	color: $color-primary;
	font-size: rem-calc(44px);
	font-weight: $extra-light;
}

.no-margin-top { margin-top: 0 !important; }
.no-margin-bottom { margin-bottom: 0 !important; }
.no-margin-left { margin-left: 0 !important; }
.no-margin-right { margin-right: 0 !important; }