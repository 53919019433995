/* ============================================================================
   Component: Buttons
   ========================================================================= */

$button-font-size: null !default;
$button-line-height: null !default;
$button-padding: emCalc(4px) emCalc(14px) emCalc(3px) emCalc(14px) !default;
$button-margin: 0 emCalc(10px) emCalc(10px) 0 !default;

$button-large-font-size: rem-calc(18px) !default;
$button-large-line-height: emCalc(26px) !default;
$button-large-padding: rem-calc(5px) rem-calc(20px) !default;
$button-large-margin: null !default;

$button-xlarge-font-size: rem-calc(20px) !default;
$button-xlarge-line-height: null !default;
$button-xlarge-padding: rem-calc(15px) rem-calc(20px) !default;
$button-xlarge-margin: null !default;

$button-default-color: $color-light !default;
$button-default-background: $color-oslo-gray !default;
$button-default-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !default;
$button-default-hover-color: $color-light !default;
$button-default-hover-background: $color-oslo-gray-dark !default;
$button-default-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0.35) !default;

$button-disabled-color: #bec2c5 !default;
$button-disabled-background: $color-iron !default;
$button-disabled-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !default;
$button-disabled-hover-color: #bec2c5 !default;
$button-disabled-hover-background: $color-iron !default;
$button-disabled-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0.35) !default;

$button-primary-color: $color-light !default;
$button-primary-background: $color-primary !default;
$button-primary-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !default;
$button-primary-hover-color: $color-light !default;
$button-primary-hover-background: $color-primary-hover !default;
$button-primary-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0.35) !default;

$button-secondary-color: $color-light !default;
$button-secondary-background: $color-secondary !default;
$button-secondary-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !default;
$button-secondary-hover-color: $color-light !default;
$button-secondary-hover-background: $color-secondary-hover !default;
$button-secondary-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0.35) !default;

$button-grey2-color: $color-light !default;
$button-grey2-background: $color-cutty-sark !default;
$button-grey2-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !default;
$button-grey2-hover-color: $color-light !default;
$button-grey2-hover-background: $color-cutty-sark-dark !default;
$button-grey2-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0.35) !default;

$button-lightgrey2-color: $color-dark !default;
$button-lightgrey2-background: $color-porcelain !default;
$button-lightgrey2-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !default;
$button-lightgrey2-hover-color: $color-dark !default;
$button-lightgrey2-hover-background: $color-porcelain-dark !default;
$button-lightgrey2-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0.35) !default;

$button-blue-color: $color-light !default;
$button-blue-background: $color-blue !default;
$button-blue-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !default;
$button-blue-hover-color: $color-light !default;
$button-blue-hover-background: $color-blue-dark !default;
$button-blue-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0.35) !default;

$button-green-color: $color-light !default;
$button-green-background: $color-green !default;
$button-green-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !default;
$button-green-hover-color: $color-light !default;
$button-green-hover-background: $color-green-dark !default;
$button-green-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0.35) !default;

$button-orange-color: $color-light !default;
$button-orange-background: $color-orange !default;
$button-orange-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !default;
$button-orange-hover-color: $color-light !default;
$button-orange-hover-background: $color-orange-dark !default;
$button-orange-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0.35) !default;

$button-red-color: $color-light !default;
$button-red-background: $color-alizarin-crimson !default;
$button-red-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !default;
$button-red-hover-color: $color-light !default;
$button-red-hover-background: $color-alizarin-crimson-dark !default;
$button-red-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0.35) !default;

$button-white-color: $color-primary !default;
$button-white-background: $color-light !default;
$button-white-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !default;
$button-white-hover-color: $color-primary-hover !default;
$button-white-hover-background: rgba($color-light, 0.8) !default;
$button-white-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0.35) !default;

$button-category-cyan-color: $color-light !default;
$button-category-cyan-background: $color-category-cyan !default;
$button-category-cyan-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !default;
$button-category-cyan-hover-color: $color-light !default;
$button-category-cyan-hover-background: $color-category-cyan-dark !default;
$button-category-cyan-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0.35) !default;

$button-category-pink-color: $color-light !default;
$button-category-pink-background: $color-category-pink !default;
$button-category-pink-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !default;
$button-category-pink-hover-color: $color-light !default;
$button-category-pink-hover-background: $color-category-pink-dark !default;
$button-category-pink-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0.35) !default;

$button-category-orange-color: $color-light !default;
$button-category-orange-background: $color-category-orange !default;
$button-category-orange-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !default;
$button-category-orange-hover-color: $color-light !default;
$button-category-orange-hover-background: $color-category-orange-dark !default;
$button-category-orange-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0.35) !default;

.button {
	display: inline-block;
	color: $button-default-color;
	font-size: $button-font-size;
	line-height: $button-line-height;
	padding: $button-padding;
	margin: $button-margin;
	border: 0;
	background-color: $button-default-background;
	border-radius: 4px;
	@include single-transition(all);
	&:hover {
		text-decoration: none;
		cursor: pointer;
		color: $button-default-hover-color;
		background-color: $button-default-hover-background;
	}

	&.large {
		font-size: $button-large-font-size;
		line-height: $button-large-line-height;
		padding: $button-large-padding;
		margin: $button-large-margin;
	}
	&.xlarge {
		font-size: $button-xlarge-font-size;
		line-height: $button-xlarge-line-height;
		padding: $button-xlarge-padding;
		margin: $button-xlarge-margin;
	}
	&.expand {
		display: block;
		width: 100%;
		text-align: center;
	}

	&.primary {
		color: $button-primary-color;
		background-color: $button-primary-background;
		box-shadow: $button-primary-box-shadow;
		&:hover {
			color: $button-primary-hover-color;
			background-color: $button-primary-hover-background;
			box-shadow: $button-primary-hover-box-shadow;
		}
	}
	&.secondary {
		color: $button-secondary-color;
		background-color: $button-secondary-background;
		box-shadow: $button-secondary-box-shadow;
		&:hover {
			color: $button-secondary-hover-color;
			background-color: $button-secondary-hover-background;
			box-shadow: $button-secondary-hover-box-shadow;
		}
	}
	&.grey2 {
		color: $button-grey2-color;
		background-color: $button-grey2-background;
		box-shadow: $button-grey2-box-shadow;
		&:hover {
			color: $button-grey2-hover-color;
			background-color: $button-grey2-hover-background;
			box-shadow: $button-grey2-hover-box-shadow;
		}
	}
	&.lightgrey2 {
		color: $button-lightgrey2-color;
		background-color: $button-lightgrey2-background;
		box-shadow: $button-lightgrey2-box-shadow;
		&:hover {
			color: $button-lightgrey2-hover-color;
			background-color: $button-lightgrey2-hover-background;
			box-shadow: $button-lightgrey2-hover-box-shadow;
		}
	}
	&.blue {
		color: $button-blue-color;
		background-color: $button-blue-background;
		box-shadow: $button-blue-box-shadow;
		&:hover {
			color: $button-blue-hover-color;
			background-color: $button-blue-hover-background;
			box-shadow: $button-blue-hover-box-shadow;
		}
	}
	&.green {
		color: $button-green-color;
		background-color: $button-green-background;
		box-shadow: $button-green-box-shadow;
		&:hover {
			color: $button-green-hover-color;
			background-color: $button-green-hover-background;
			box-shadow: $button-green-hover-box-shadow;
		}
	}
	&.orange {
		color: $button-orange-color;
		background-color: $button-orange-background;
		box-shadow: $button-orange-box-shadow;
		&:hover {
			color: $button-orange-hover-color;
			background-color: $button-orange-hover-background;
			box-shadow: $button-orange-hover-box-shadow;
		}
	}
	&.red {
		color: $button-red-color;
		background-color: $button-red-background;
		box-shadow: $button-red-box-shadow;
		&:hover {
			color: $button-red-hover-color;
			background-color: $button-red-hover-background;
			box-shadow: $button-red-hover-box-shadow;
		}
	}
	&.white {
		color: $button-white-color;
		background-color: $button-white-background;
		box-shadow: $button-white-box-shadow;
		&:hover {
			color: $button-white-hover-color;
			background-color: $button-white-hover-background;
			box-shadow: $button-white-hover-box-shadow;
		}
	}
	&.category-cyan {
		color: $button-category-cyan-color;
		background-color: $button-category-cyan-background;
		box-shadow: $button-category-cyan-box-shadow;
		&:hover {
			color: $button-category-cyan-hover-color;
			background-color: $button-category-cyan-hover-background;
			box-shadow: $button-category-cyan-hover-box-shadow;
		}
	}
	&.category-pink {
		color: $button-category-pink-color;
		background-color: $button-category-pink-background;
		box-shadow: $button-category-pink-box-shadow;
		&:hover {
			color: $button-category-pink-hover-color;
			background-color: $button-category-pink-hover-background;
			box-shadow: $button-category-pink-hover-box-shadow;
		}
	}
	&.category-orange {
		color: $button-category-orange-color;
		background-color: $button-category-orange-background;
		box-shadow: $button-category-orange-box-shadow;
		&:hover {
			color: $button-category-orange-hover-color;
			background-color: $button-category-orange-hover-background;
			box-shadow: $button-category-orange-hover-box-shadow;
		}
	}

	&[disabled=disabled], &:disabled, &.disabled {
		color: $button-disabled-color;
		background-color: $button-disabled-background;
		&:hover {
			color: $button-disabled-hover-color;
			background-color: $button-disabled-hover-background;
		}
	}

	&.button-clear {
		color: #a4a8ab;
		background-color: transparent !important;
		box-shadow: none !important;
		.font-icon:before {
			font-size: rem-calc(16px);
			line-height: rem-calc(24px);
		}
		&:hover {
			text-decoration: underline;
		}
		&.primary {
			color: $button-primary-color;
			&:hover {
				color: $button-primary-hover-color;
			}
		}
		&.secondary {
			color: $button-secondary-color;
			&:hover {
				color: $button-secondary-hover-color;
			}
		}
		&.blue {
			color: $button-blue-color;
			&:hover {
				color: $button-blue-hover-color;
			}
		}
		&.green {
			color: $button-green-color;
			&:hover {
				color: $button-green-hover-color;
			}
		}
		&.red {
			color: $button-red-color;
			&:hover {
				color: $button-red-hover-color;
			}
		}
		&.white {
			color: $button-white-color;
			&:hover {
				color: $button-white-hover-color;
			}
		}
	}

	&.button-dashed {
		color: #a4a8ab;
		background-color: transparent !important;
		box-shadow: none !important;
		border: 1px dashed #a4a8ab;
		padding: emCalc(3px) emCalc(13px) emCalc(2px) emCalc(13px);

		&.large {
			padding: rem-calc(4px) rem-calc(19px);
		}
		&.xlarge {
			padding: rem-calc(14px) rem-calc(19px);
		}

		.font-icon:before {
			font-size: rem-calc(16px);
			line-height: rem-calc(24px);
		}
		&.primary {
			color: $button-primary-color;
			border-color: $button-primary-background;
			&:hover {
				color: $button-primary-hover-color;
				border-color: $button-primary-hover-background;
			}
		}
		&.secondary {
			color: $button-secondary-color;
			border-color: $button-secondary-background;
			&:hover {
				color: $button-secondary-hover-color;
				border-color: $button-secondary-hover-background;
			}
		}
		&.blue {
			color: $button-blue-color;
			border-color: $button-blue-background;
			&:hover {
				color: $button-blue-hover-color;
				border-color: $button-blue-hover-background;
			}
		}
		&.green {
			color: $button-green-color;
			border-color: $button-green-background;
			&:hover {
				color: $button-green-hover-color;
				border-color: $button-green-hover-background;
			}
		}
		&.red {
			color: $button-red-color;
			border-color: $button-red-background;
			&:hover {
				color: $button-red-hover-color;
				border-color: $button-red-hover-background;
			}
		}
		&.white {
			color: $button-white-color;
			border-color: $button-white-background;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1) !important;
			}
		}
	}
}