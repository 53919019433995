/* ============================================================================
   Page: Profile
   ========================================================================= */

$profile-headline-font-size: rem-calc(28px) !default;
$profile-headline-line-height: rem-calc(34px) !default;

.profile {
	.headline {
		font-size: $profile-headline-font-size;
		line-height: $profile-headline-line-height;
	}
	.image {
		img {
			max-width: 100%;
			border-radius: 50%;
		}
	}
}