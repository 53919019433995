/* ============================================================================
   Component: Tabs
   ========================================================================= */

$tabs-padding: null !default;
$tabs-margin: 0 !default;
$tabs-nav-text-align: left !default;
$tabs-nav-line-height: 0 !default;
$tabs-nav-border-bottom: 1px solid $color-border-grey !default;
$tabs-nav-item-color: $color-gray-chateau !default;
$tabs-nav-item-color-hover: $color-gray-chateau !default;
$tabs-nav-item-font-size: rem-calc(18px) !default;
$tabs-nav-item-line-height: 2.5 !default;
$tabs-nav-item-text-decoration: none !important !default;
$tabs-nav-item-padding: 0 rem-calc(5px) !default;
$tabs-nav-item-margin: 0 rem-calc(20px) rem-calc(-1px) 0 !default;
$tabs-nav-item-border-bottom: 0 !default;
$tabs-nav-item-hover-border-bottom: 2px solid $color-primary !default;
$tabs-nav-item-current-color: darken($color-gray-chateau, 15%) !important !default;
$tabs-nav-item-current-border-bottom: 2px solid $color-primary !default;
$tabs-nav-item-current-background: none !default;
$tabs-content-margin: rem-calc(15px) 0 !default;
$tabs-content-section-padding: 0 !default;
$tabs-content-section-margin: rem-calc(15px) auto 0 !default;

.tabs {
	position: relative;
	width: 100%;
	overflow: hidden;
	padding: $tabs-padding;
	margin: $tabs-margin;

	nav {
		text-align: $tabs-nav-text-align;
		line-height: $tabs-nav-line-height;
		border-bottom: $tabs-nav-border-bottom;
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			display: inline-block;
			li {
				display: block;
				float: left;
				position: relative;
				border-bottom: $tabs-nav-item-border-bottom;
				a {
					display: block;
					white-space: nowrap;
					color: $tabs-nav-item-color;
					font-size: $tabs-nav-item-font-size;
					line-height: $tabs-nav-item-line-height;
					text-decoration: $tabs-nav-item-text-decoration;
					padding: $tabs-nav-item-padding;
					margin: $tabs-nav-item-margin;
					&:hover {
						color: $tabs-nav-item-color-hover;
						border-bottom: $tabs-nav-item-hover-border-bottom;
					}
				}
				&.tab-current {
					z-index: 100;
					a {
						color: $tabs-nav-item-current-color;
						border-bottom: $tabs-nav-item-current-border-bottom;
						background: $tabs-nav-item-current-background;
					}
				}
			}
		}
		.icon {
			display: none;
		}
	}

	.content {
		margin: $tabs-content-margin;
		section {
			display: none;
			padding: $tabs-content-section-padding;
			margin: $tabs-content-section-margin;
			&:before, &:after {
				content: '';
				display: table;
			}
			&:after {
				clear: both;
			}
			&.content-current {
				display: block;
			}
		}
		.no-js & {
			display: block;
			padding-bottom: 2em;
			border-bottom: 1px solid #47a3da;
		}
	}
}

@media screen and (max-width: $break-small) {
	/* Icons */
	.tabs {
		nav {
			a {
				&:before {
					display: inline-block;
				}
				span {
					display: none;
				}
			}
			.icon {
				display: inline-block;
				width: 70px;
				height: auto;
			}
			ul,
			ul li a,
			ul li.tab-current a {
				width: 100%;
				padding: 0;
				background: none;
			}
			ul {
				li {
					width: 25%;
					width: calc(25% + -10px);
					margin: 0 5px 0 5px;
					border-bottom: 0;
					text-align: center;
					&:last-child {
						border-right: none;
					}
				}
			}
		}
	}
}