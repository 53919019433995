/* ============================================================================
   Abstract: Mixins
   ========================================================================= */

$icon-url: url(~img/icons.png) !default;

// IMPORT ONCE (Zurb's foundation)
// We use this to prevent styles from being loaded multiple times for components that rely on other components.
$modules: () !default;
@mixin exports($name) {
	// Import from global scope
	$modules: $modules !global;
	// Check if a module is already on the list
	$module_index: index($modules, $name);
	@if (($module_index == null) or ($module_index == false)) {
		$modules: append($modules, $name) !global;
		@content;
	}
}

// Makes an element visually hidden, but accessible.
@mixin element-invisible {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
}

// Turns off the element-invisible effect.
@mixin element-invisible-off {
	position: static !important;
	height: auto;
	width: auto;
	overflow: visible;
	clip: auto;
}

// Center vertically
// With absolute positioning and translateY
// Requires the parent element to be a positioned element
@mixin center-vertically {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

@mixin vertical-align {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

// Default Icon
@mixin icon {
	vertical-align: top;
	background-image: $icon-url;
	background-position: -3000px -3000px;
	background-repeat: no-repeat;
}

// Transitions
@mixin single-transition($property:all, $speed:300ms, $ease:ease-out) {
	transition: $property $speed $ease;
}

@mixin word-wrap() {
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

// @hamburger
//
// We use this to create the icon with three lines aka the hamburger icon, the menu-icon or the navicon
// $width - Width of hamburger icon in rem
// $left - If false, icon will be centered horizontally || explicitly set value in rem
// $top - If false, icon will be centered vertically || explicitly set value in rem
// $thickness - thickness of lines in hamburger icon, set value in px
// $gap - spacing between the lines in hamburger icon, set value in px
// $color - icon color
// $hover-color - icon color during hover
// $offcanvas - Set to true of @include in offcanvas
@mixin hamburger($width, $left, $top, $thickness, $gap, $color, $hover-color, $offcanvas) {
	span::after {
		content: "";
		position: absolute;
		display: block;
		height: 0;

		@if $offcanvas {
			@if $top {
				top: $top;
			}
			@else {
				top: 50%;
				margin-top: (-$width/2);
			}
			@if $left {
				left: $left;
			}
			@else {
				left: ($tabbar-menu-icon-width - $width)/2;
			}
		}
		@else {
			top: 50%;
			// margin-top: -($width/2);
			margin-top: -8px;
			// right: $topbar-link-padding;
			@if $menubar-menu-icon-position == left {
				left: 0;
			} @else {
				right: 0;
			}
		}

		box-shadow:
			0 0 0 $thickness $color,
			0 $gap + $thickness 0 $thickness $color,
			0 (2 * $gap + 2*$thickness) 0 $thickness $color;
		width: $width;
	}
	span:hover:after {
		box-shadow:
			0 0 0 $thickness $hover-color,
			0 $gap + $thickness 0 $thickness $hover-color,
			0 (2 * $gap + 2*$thickness) 0 $thickness $hover-color;
	}
}

// Alertboxes
$alertbox-grey-padding: 1em !default;
$alertbox-grey-border: 1px solid $color-light !default;

$alertbox-grey-color: $color-grey !default;
$alertbox-grey-border-color: $color-mercury !default;
$alertbox-grey-background-color: $color-athens-gray !default;

$alertbox-red-color: #b82a2a !default;
$alertbox-red-border-color: #b82a2a !default;
$alertbox-red-background-color: #f7e7e7 !default;

@mixin alertBox_std {
	padding: $alertbox-grey-padding;
	border: $alertbox-grey-border;
}

@mixin alertBox_grey {
	@include alertBox_std;
	background-color: $alertbox-grey-background-color;
	border-color: $alertbox-grey-border-color;
	color: $alertbox-grey-color;
}

@mixin alertBox_red {
	@include alertBox_std;
	background-color: $alertbox-red-background-color;
	border-color: $alertbox-red-border-color;
	color: $alertbox-red-color;
}

// Custom
@mixin fonticon($icon, $position: "before", $replace: false) {
  @if $replace {
    font-size: 0;
  }
  &:#{$position} {
    @extend .font-icon-#{$icon};
    font-family: 'll_appbuilder_iconfont';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    text-decoration: none;
    vertical-align: top;
    font-size: 1em;
    @if $replace {
      font-size: 1em;
    }
    @content;
  }
}