/* ============================================================================
   Component: Data actions
   ========================================================================= */

.data-actions {

	&__list {
		padding: 0;
		margin: 0;
	}

	&__item {
		font-size: rem-calc(18px);

		&:not(:last-child) {
			margin-bottom: em-calc(10px);
		}
	}

}