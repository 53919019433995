/* ============================================================================
   Abstract: Functions
   ========================================================================= */

$base-font-size: 16px !default;

@function lower-bound($range){
	@if length($range) <= 0 {
		@return 0;
	}
	@return nth($range,1);
}

@function upper-bound($range) {
	@if length($range) < 2 {
		@return 999999999999;
	}
	@return nth($range, 2);
}

@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

@function emCalc($px, $base: $base-font-size) {
	@return $px / $base * 1em;
}

@function rem-calc($px) {
	@return $px / $base-font-size * 1rem;
}

// Calculates the pixel position from a given grid
// Usage:
// iconsGridPos(10 20);
// iconsGridPos(10 20, 50px);
@function iconsGridPos($values, $grid-base: 25px) {
	$max: length($values);

	@if $max == 1 { @return gridPos-calc(nth($values, 1), $grid-base); }

	$iconValues: ();
	@for $i from 1 through $max {
	$iconValues: append($iconValues, gridPos-calc(nth($values, $i), $grid-base));
	}
	@return $iconValues;
}

@function gridPos-calc($value, $grid-base: 25px)  {
	$value: strip-unit($value) * strip-unit($grid-base) * -1px;
	@if ($value == 0px) { $value: 0; } // Turn 0px into 0
	@return $value;
}