/* ============================================================================
   Component: Card
   ========================================================================= */

$card-background: $color-light !default;
$card-border-radius: 4px !default;
$card-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2) !default;
$card-head-padding: rem-calc(20px) !default;
$card-head-border-bottom: 1px solid #dcdcdc !default;
$card-head-background: null !default;
$card-content-padding: rem-calc(20px) !default;
$card-content-background: #f7f9fa !default;
$card-headline-font-size: rem-calc(22px) !default;
$card-headline-font-weight: $regular !default;
$card-buttons-margin: rem-calc(15px) 0 0 0 !default;

.card {
	position: relative;
	background: $card-background;
	overflow: hidden;
	border-radius: $card-border-radius;
	box-shadow: $card-box-shadow;
	@include single-transition(box-shadow);
	&__head {
		padding: $card-head-padding;
		border-bottom: $card-head-border-bottom;
		background: $card-head-background;
	}
	&__content {
		padding: $card-content-padding;
		background: $card-content-background;
	}
	&__buttons {
		margin: $card-buttons-margin;
	}
	.headline {
		font-size: $card-headline-font-size;
		font-weight: $card-headline-font-weight;
	}
}