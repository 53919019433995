/* ============================================================================
   Base: Userarea
   ========================================================================= */

/*
  Default Elements
*/
.row {
	&--full-width, &--full-width > .wrapper {
		max-width: 100% !important; // ToDo: Better without important
	}
	&--iframe {
		@if $iframe-fullsize {
			max-width: 100% !important; // ToDo: Better without important
			@media only screen and (min-width: $break-medium) {
				padding-left: rem-calc(20px);
				padding-right: rem-calc(20px);
			}
		}
	}
}

.headerImg .wrapper, .globalLayerShell, .globalLayerShell .wrapper, .head-overlay .wrapper {
	max-width: none !important;
}

ul {
	list-style-type: none;
}

/*
  Massdata
*/
.massDataElsShell {
	.search-button {
		top: 8px;
		right: 22px;
		padding: 0;
		margin: 0;
		background: none;
		border: 0;
		color: $color-iron-dark;
		box-shadow: none;
		@include single-transition(all);
	}
	.sort, .search
	{
		position: relative;
		input {
			display: block;
			font-size: rem-calc(16px);
			height: rem-calc(40px);
			width: 100%;
			padding: 0 rem-calc(18px);
			border: 2px solid $color-iron-dark;
			background: $color-light;
			border-radius: 4px;
			&:hover, &:focus {
				border-color: $color-iron-x-dark;
			}

			&::placeholder {
				color: $color-dark;
			}
		}
	}
	.search
	{
		input {
			&:hover, &:focus {
				+.search-button {
					color: $color-iron-x-dark;
				}
			}
		}
	}
	&.custom select {
		color: #999;
		border-color: $color-iron-dark;
		border-radius: 4px;
		@include single-transition(all);
		&:hover {
			border-color: $color-iron-x-dark;
		}
	}
}

.edit-button {
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 7;
	color: $color-primary;
	-webkit-backface-visibility: hidden;
	margin: rem-calc(10px);
	&:hover {
		color: $color-primary-hover;
	}
}