/* ============================================================================
   Base: Print
   ========================================================================= */

@media print {
	.pageStyle_tasks {

		.waf-mainnavpanel,
		.waf-mainpanel,
		.contentTeaserTopContainer,
		.head {
			display: none;
		}

		#livingapps-form {
			display: block !important;
			width: 100%;

			.formbuilder-cascade .llft-control input {
				border: 0;
				box-shadow: none;
				border-bottom: 1px solid #CACACA;
			}

			.font-optionen {
				display: none;
			}

			.internal-fields-wrapper {
				background: #fff;
				border: 0;
				padding: 0;
				margin: 0;
			}
		}

		.task-action-bar {
			display: none;
		}

		#waf .waf-look-panel {
			background-color: white;
			border: 0;
			box-shadow: none;
		}


	}
}