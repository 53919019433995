/* ============================================================================
   Component: Usermenu
   ========================================================================= */

$usermenu-dropdown-item-color: $color-primary !default;
$usermenu-dropdown-item-hover-color: $color-primary-hover !default;
$usermenu-dropdown-separator-padding: rem-calc(7.5px) 0 rem-calc(5px) !default;

.usermenu {
	left: auto;
	right: 10px;
	top: 0;
	z-index: 101;
	&.sticky {
		position: fixed;
		right: 10px;
		.usermenu-wrapper {
			margin: 16px 0;
		}
	}
	@media only screen and (max-width: $break-medium) {
		right: 50px;
	}
}

.usermenu-wrapper {
	margin: 18px 0;
	@media only screen and (max-width: $break-medium) {
		margin: 10px 0;
	}
}

.usermenu-item {
	display: inline-block;
	vertical-align: top;
	margin: 0 20px 0 0;
	height: 35px;

	&:last-child {
		margin: 0;
	}

	&--login {
		.button {
			height: 100%;
			margin: 0;
		}
	}
}

.usermenu-profile-avatar {
	@extend .clearfix;
	&:hover {
		.font-icon-down {
			color: $color-iron-x-dark;
		}
	}
}

.usermenu-profile-avatar-dropdown-wrapper {
	@extend .clearfix;
	position: relative;
	float: left;
}

.usermenu-profile-avatar-dropdown-button {
	float: left;
	color: #caced1;
	.font-icon:before {
		font-size: 14px;
		line-height: 35px;
	}
	&.usermenu-close {
		.font-icon-down {
			color: $color-iron-x-dark;
		}
	}
}

.usermenu-profile-avatar-image {
	float: left;
	position: relative;
	overflow: hidden;
	width: 35px;
	height: 35px;
	margin-right: 3px;
	background: $color-athens-gray;
	border-radius: 50%;
	img {
		width: 100%;
		height: 100%;
	}
}

.usermenu-profile-avatar-placeholder {
	float: left;
	position: relative;
	overflow: hidden;
	width: 35px;
	height: 35px;
	margin-right: 3px;
	background: $color-athens-gray;
	border-radius: 50%;
	.font-icon {
		position: absolute;
		left: -2px;
		bottom: -8px;
		color: #caced1;
		&:before {
			font-size: 40px;
		}
	}
}

.usermenu-profile-avatar-dropdown {
	border-radius: 4px;
	display: none;
	position: absolute;
	right: 0;
	top: 43px;
	width: auto;
	padding: 15px 35px 15px 20px;
	margin: 0;
	background: $color-light;
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.25);
	cursor: auto;
	z-index: 6;
}

.usermenu-profile-avatar-dropdown-item {
	padding: 4px 0;
	a {
		color: $usermenu-dropdown-item-color;
		&:hover {
			color: $usermenu-dropdown-item-hover-color;
		}
	}
	&:first-child {
		padding-top: 0;
	}
	&:last-child {
		padding-bottom: 0;
	}
	.username {
		font-weight: $semi-bold;
	}
	.account {
		font-size: 14px;
		line-height: 18px;
	}
}

.usermenu-profile-avatar-dropdown-separator {
	.separatorShell {
		padding: $usermenu-dropdown-separator-padding 0;
	}
}