/* ============================================================================
   Component: Tags
   ========================================================================= */

$tag-color: $color-gray-chateau !default;
$tag-font-size: rem-calc(14px) !default;
$tag-line-height: rem-calc(24px) !default;
$tag-padding: 0 rem-calc(10px) 0 0 !default;
$tag-margin: null !default;

$tag-icon-color: null !default;
$tag-icon-font-size: rem-calc(13px) !default;
$tag-icon-padding: rem-calc(5px) rem-calc(2px) 0 0 !default;
$tag-icon-margin: null !default;

.tag {
	display: inline-block;
	font-size: $tag-font-size;
	line-height: $tag-line-height;
	color: $tag-color;
	padding: $tag-padding;
	margin: $tag-margin;
	.font-icon:before {
		font-size: $tag-icon-font-size;
		padding: $tag-icon-padding;
		margin: $tag-icon-margin;
		color: $tag-icon-color;
	}
}