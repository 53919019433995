/* ============================================================================
   Component: Logo
   ========================================================================= */

$logo-svg: url('#{$image-path}/logo.svg') !default;
$logo-png: url('#{$image-path}/logo.png') !default;

$logo-head-height: rem-calc(50px) !default;
$logo-head-width: rem-calc(220px) !default;
$logo-head-max-height: rem-calc(40px) !default;
$logo-head-top: 10px !default;
$logo-head-left: 30px !default;

.livingapps-logo {
	margin: 0;
	text-indent: 100%;
	overflow: hidden;
	white-space: nowrap;

	&--login {
		height: rem-calc(70px);
		margin: 0 auto rem-calc(10px) auto;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;

		@if $logo-svg {
			background-image: $logo-svg;
			.no-svg & {
				background-image: $logo-png;
			}
		} @else {
			background-image: $logo-png;
		}
	}

	&--head {
		display: inline-block;
		vertical-align: middle;
		height: $logo-head-height;
		max-height: $logo-head-max-height;
		width: $logo-head-width;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: left center;

		@if $logo-svg {
			background-image: $logo-svg;
			.no-svg & {
				background-image: $logo-png;
			}
		} @else {
			background-image: $logo-png;
		}

		@media screen and (min-width: $break-medium) {
			position: absolute;
			top: $logo-head-top;
			left: $logo-head-left;
			z-index: 2;
			max-height: none;
		}
	}
}