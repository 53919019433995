// Add foundation mediaqueries
$small-range: (0em, $break-small) !default;
$medium-range: ($break-small, $break-medium) !default;
$large-range: ($break-medium, 99999999em) !default;

$screen: "only screen" !default;

$small-up: $screen !default;
$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
