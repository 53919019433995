/* ============================================================================
   Base: CMS Responsive
   ========================================================================= */

// Add foundation mediaqueries
$small-range: (0em, $break-small) !default;
$medium-range: ($break-small, $break-medium) !default;
$large-range: ($break-medium, 99999999em) !default;

$screen: "only screen" !default;

$small-up: $screen !default;
$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;

meta.foundation-mq-small {
	font-family: "/" + unquote($small-up) + "/";
	width: lower-bound($small-range);
}

meta.foundation-mq-medium {
	font-family: "/" + unquote($medium-up) + "/";
	width: lower-bound($medium-range);
}

meta.foundation-mq-large {
	font-family: "/" + unquote($large-up) + "/";
	width: lower-bound($large-range);
}

// just for big screens
@media print, screen and (min-width: 640px) {
	.footer .logo
	{
		margin-top: emCalc(6px, 14px);
		vertical-align: top;
	}
}

// Lower than the standard grid size:
@media only screen and (max-width: 959px) {
	.wrapper,
	.globalLayerShell,
	.flOuterShell_slide .des1
	{
		width: 100%;
	}

	#content
	{
		width: 73%;
		margin-left: 0;
	}

	#sidebar-left
	{
		display: none;
	}

	.multiCol_1 .col, .massDataCol_1 .col {width: 100%;}
	.multiCol_2 .col, .massDataCol_2 .col {width: 50%;}
	.multiCol_3 .col, .massDataCol_3 .col {width: 33.33333%;}
	.multiCol_3_flexible .col, .massDataCol_3_flexible .col {width: 50%;}
	.multiCol_4 .col, .massDataCol_4 .col {width: 50%;}
	.multiCol_5 .col, .massDataCol_5 .col {width: 50%;}
	.multiCol_6 .col, .massDataCol_6 .col {width: 50%;}
	.multiCol_7 .col, .massDataCol_7 .col {width: 50%;}
	.multiCol_8 .col, .massDataCol_8 .col {width: 50%;}
	.multiCol_9 .col, .massDataCol_9 .col {width: 50%;}
	.multiCol_10 .col, .massDataCol_10 .col {width: 50%;}

	.jsSliderShell .prev, .jsSliderShell .next
	{
		display: none;
	}

	.contentStyle_woTeasers .jsSliderShell
	{
		margin: 0;
		padding: 0;
	}

	/* Custom Form */
	.custom
	{
		.inline.right {
			float: left !important;
			padding-bottom: 0;
			.labelSubTitle {
				display: inline;
			}
		}
	}
}

// Tablet stuff (mobile nav activation):
@media only screen and (max-width: $break-medium) {
	.qAccessCont,
	.bottomAddContrContainer,
	.topAddContrContainer
	{
		display: none;
	}
}

// mobile stuff
@media only screen and (max-width: $break-small) {
	h1, .h1
	{
		font-size: emCalc(30px);
		line-height: emCalc(45px, 38px);
	}

	#content,
	.contentStyle_rightTeaser #content,
	.contentStyle_leftTeaser #content
	{
		width: 100%;
		float: none;
		margin-left: 0;
		margin-right: 0;
	}

	#sidebar-right
	{
		width: 100%;
		float: none;
		clear: left;
	}

	#sidebar-right:after
	{
		content: ".";
		display: block;
		height: 0;
		clear: both;
		visibility: hidden;
	}

	#sidebar-right .teaserItem,
	#sidebar-left .teaserItem
	{
		width: 100%;
		position: relative;
	}

	.multipleColumnShell .col, .massDataOuterShell .col {width: 100%;}

	/* DataManagement Table */
	.dataManagementTable
	{
		th {
			padding: emCalc(5px) emCalc(10px);
		}
		td {
			padding: emCalc(2px) emCalc(15px);
		}
	}

	/* Catalog List */
	.rightTeaser .multiCol_2
	{
		.col_1 {
			width: 100%;
			padding-right: $grid-gutter-pixel;
			border: 0;
			.massDataOuterShell {
				border: 0;
			}
		}
		.col_2 {
			width: 100%;
			padding-left: $grid-gutter-pixel;
		}
	}

	.leftTeaser .multiCol_2
	{
		.col_1 {
			width: 100%;
		}
		.col_2 {
			width: 100%;
		}
	}
}


// Retina
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
	.icon,
	.ui-accordion .ui-accordion-header .ui-icon,
	.jsSliderShell .prev, .jsSliderShell .next,
	.contentNavigationShell .icon,
	.jsSliderShell .pagination li a
	{
	}

	.pPages .icon
	{
		background-image: url(~img/icons@2x.png);
		background-size: $icons-sprite-total-width $icons-sprite-total-height;
	}
}
