/* ============================================================================
   Component: Attachments
   ========================================================================= */

[v-cloak] {
	display: none;
}

.attachments {
	&__error {
		color: $color-red-dark;
		margin-bottom: 0; 
	}
	
	&__group {
		// margin: 0 0 emCalc(20px) 0;

		// &:last-child {
		// 	margin-bottom: 0;
		// }
	}

	&__title {
		display: none;
		font-size: rem-calc(12px);
		font-weight: $semi-bold;
	}

	&__item {
		margin: rem-calc(15px) 0;

		&:last-child {
			margin-bottom: 0;
		}

		&__icons {
			a, a:hover {
				color: $color-grey;
			}
			position: relative;
			z-index: 10;
			color: $color-grey;
			font-size: rem-calc(10px);
			line-height: 0;
			&__icon-delete,
			&__icon-download {
				position: absolute;
				right: 0;
				top: 24px;
				cursor: pointer;
				opacity: .4;
				&:hover {
					opacity: 1;
				}
			}
			&__icon-download {
				top: 23px;
				right: 20px;
				height: 13px;
				text-align: center;
				border-bottom: 2px solid $color-grey;
			}
		}

		&__image {
			display: inline-block;
			vertical-align: top;
			max-height: rem-calc(40px);
			max-width: rem-calc(40px);
			height: rem-calc(40px);
			width: rem-calc(40px);
			margin-top: rem-calc(5px);
			margin-right: rem-calc(15px);

			i {
				border-radius: 4px;
				margin: 0;
				text-indent: 100%;
				overflow: hidden;
				white-space: nowrap;
				display: inline-block;
				vertical-align: middle;
				width: 100%;
				max-width: 100%;
				height: 100%;
				max-height: 100%;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
				background-color: #ccc;
				cursor: pointer;
			}
		}

		&__content {
			display: inline-block;
			vertical-align: top;
		}

	}

	&__label {
		font-weight: $semi-bold;
		display: block;
		padding: 0 18px 0 0;
		/* These are technically the same, but use both */
		/* This breaks the word wherever it is without a hyphen */
		overflow-wrap: break-word;
		word-wrap: break-word;
		/* Adds a hyphen where the word breaks */
		-webkit-hyphens: auto;
		-ms-word-break: break-all;
		/* This is the dangerous one in WebKit, as it breaks things wherever */
		word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;
		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
		&::before {
			color: $color-gray-chateau;
			margin-left: -4px;
		}
	}

	&__date {
		font-size: rem-calc(12px);
		line-height: 1.25em;
		display: block;
	}

	&__text {
		font-size: rem-calc(14px);
		line-height: 1.25em;
		display: block;
		margin-top: rem-calc(5px);
		/* These are technically the same, but use both */
		/* This breaks the word wherever it is without a hyphen */
		overflow-wrap: break-word;
		word-wrap: break-word;
		/* Adds a hyphen where the word breaks */
		-webkit-hyphens: auto;
		-ms-word-break: break-all;
		/* This is the dangerous one in WebKit, as it breaks things wherever */
		word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;
		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
		&__more {
			margin: 0 0 0 5px;
		}
	}

	&__encryption-flag-outer {
		margin: 5px 0 10px;
		.field {
			margin-right: 8px;
		}
		.label {
			display: inline;
			background: transparent;
			text-align: left;
			color: $color-dark;
			font-weight: normal;
		}
	}
}