/* ============================================================================
   Component: Embed
   ========================================================================= */

$embed-margin: 0 0 rem-calc(16px) 0 !default;
$embed-label-font-size:  rem-calc(16px) !default;
$embed-label-margin: 0 0 rem-calc(8px) 0 !default;
$embed-code-padding: rem-calc(10px) rem-calc(20px) !default;
$embed-code-margin: 0 !default;
$embed-code-background: $color-mercury !default;
$embed-code-image-max-height: rem-calc(200px) !default;
$embed-social-item-margin: 0 rem-calc(10px) 0 0 !default;
$embed-social-item-image-max-width: rem-calc(32px) !default;
$embed-preview-disable: false !default;
$embed-share-disable: false !default;

@if $embed-preview-disable {
	.mylivingapps-embed-preview {
		display: none;
	}
}

@if $embed-share-disable {
	.mylivingapps-embed-share {
		display: none;
	}
}

.embed {
	margin: $embed-margin;

	&--code {
	}

	&--share {
	}

	&--last, &:last-child {
		margin-bottom: 0;
	}

	&__label {
		font-size: $embed-label-font-size;
		margin: $embed-label-margin;
	}

	&__code, &__code-image {
		padding: $embed-code-padding;
		margin: $embed-code-margin;
		background: $color-mercury;
		border-radius: 4px;
	}

	&__code {
		cursor: pointer;
		word-break: break-word;
	}

	&__code-image {
		text-align: center;
		img {
			max-height: $embed-code-image-max-height;
		}
	}

	&__social {
		&__item {
			display: inline-block;
			margin: $embed-social-item-margin;
			img {
				max-width: $embed-social-item-image-max-width;
			}
		}
	}

}

.mylivingapps-embed {
	.tabs .content {
		margin-bottom: 0;
	}
}