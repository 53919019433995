/* ============================================================================
   Page: MyLivingApps Evaluation
   ========================================================================= */

.mylivingapps-evaluation {
	label {
		font-size: rem-calc(16px);
		font-weight: $semi-bold;
		padding-top: 0;
	}
	input, textarea {
		font-size: rem-calc(18px);
		padding: rem-calc(8px) rem-calc(16px);
	}
	select {
		font-size: rem-calc(18px);
	}
	h1, .headline {
		font-size: rem-calc(28px);
		line-height: 1.2;
		margin-top: rem-calc(16px);
		text-align: left;
	}
	h2 {
		font-size: rem-calc(22px);
		margin-bottom: rem-calc(10px);
	}
	.panel .columns:first-child {
		.headline {
			margin-top: 0;
		}
	}
}