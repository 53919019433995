/* ============================================================================
   Page: MyLivingApps Overview
   ========================================================================= */

$mylivingapps-overview-panel-headline-overflow: false !default;

.catalog-top-slider-wrapper {
	padding: rem-calc(10px);
	background: $color-light;
	border-radius: 4px;
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.cS-hidden {
	height: 1px;
	opacity: 0;
	overflow: hidden;
}

.catalog-top-slider {
	padding-right: 25% !important;
	.headline {
		font-size: rem-calc(48px);
		line-height: rem-calc(52px);
	}
	.description {
		font-size: rem-calc(18px);
		line-height: rem-calc(24px);
	}
	.overlay-content {
		position: absolute;
		top: 110px;
		left: 50px;
	}
	&.vertical {
		.lSGallery {
			width: 25% !important;
			border-radius: 0 4px 4px 0;
			li {
				opacity: 0.6;
				@include single-transition(all);
				&.active, &:hover {
					opacity: 1;
					border-radius: 0;
				}
			}
		}
		.lightSlider > * {
			position: relative;
			border-radius: 4px 0 0 4px;
			overflow: hidden;
		}
	}
}

.mylivingapps-overview-wrapper {
	.massDataOutermostShell {
		@extend .row;
	}
	.sworDes1 {
		text-align: center;
		font-size: rem-calc(24px);
		padding: rem-calc(50px) rem-calc(20px);
		border: 0;
		background: none;
	}
}

.mylivingapps-overview {
	.massDataOuterShell {
		padding: 0;
		margin: 0;
	}
}

.el_mylivingapps-overview {
	.fields {
		padding-right: $grid-gutter-pixel;
		padding-left: $grid-gutter-pixel;
	}
}

.mylivingapps-overview {
	.panel {
		min-height: 275px;
		padding: 20px 9%;
		&:hover {
			box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
			.headline a {
				color: $color-primary-hover;
			}
			.image {
				opacity: 0.9;
				@include single-transition(opacity);
			}
		}
		.headline {
			font-size: rem-calc(16px);
			font-weight: $semi-bold;
			color: $color-primary;
			a:hover {
				text-decoration: none;
				color: $color-primary-hover;
			}

			@if not($mylivingapps-overview-panel-headline-overflow) {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
		.description {
			max-height: 48px;
			overflow: hidden;
		}
	}
}