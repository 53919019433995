/* ============================================================================
   Component: Notify Row
   ========================================================================= */

$notifyrow-height: rem-calc(68px) !default;
$notifyrow-padding: rem-calc(15px) 0 !default;
$notifyrow-background: lighten($color-river-bed, 10%) !default;
$notifyrow-message-font-size: rem-calc(18px) !default;
$notifyrow-message-line-height: rem-calc(21px) !default;
$notifyrow-message-color: $color-light !default;

.notify-row
{
	display: none;
	position: absolute;
	left: 0;
	width: 100%;
	padding: $notifyrow-padding;
	top: $notifyrow-height;
	background: $notifyrow-background;
	text-align: center;
	.message {
		font-size: $notifyrow-message-font-size;
		line-height: $notifyrow-message-line-height;
		color: $notifyrow-message-color;
	}
	&.blue {
		background: $color-blue;
	}
	&.green {
		background: #26b750;
	}
	&.red {
		background: #b63637;
	}
}