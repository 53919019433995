//
// Global Variables
//

$default-float: left;
$em-base: 16px;

// It strips the unit of measure and returns it
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// Converts "px" to "em" using the ($)em-base
@function convert-to-em($value, $base-value: $em-base)  {
  $value: strip-unit($value) / strip-unit($base-value) * 1em;
  @if ($value == 0em) { $value: 0; } // Turn 0em into 0
  @return $value;
}

// Working in ems is annoying. Think in pixels by using this handy function, em-calc(#)
// Just enter the number, no need to mention "px"
@function em-calc($values, $base-value: $em-base) {
  $max: length($values); // Get the total number of parameters passed

  // If there is only 1 parameter, then return it as an integer.
  // This is done because a list can't be multiplied or divided even if it contains a single value
  @if $max == 1 { @return convert-to-em(nth($values, 1), $base-value); }

  $emValues: (); // This will eventually store the converted $values in a list
  @for $i from 1 through $max {
    $emValues: append($emValues, convert-to-em(nth($values, $i), $base-value));
  }
  @return $emValues;
}

//
// Table Variables
//
$include-html-table-classes: false;

// These control the background color for the table and even rows
$table-bg: #fff;
$table-even-row-bg: #f9f9f9;

// These control the table cell border style
$table-border-style: solid;
$table-border-size: 1px;
$table-border-color: #ddd;

// These control the table head styles
$table-head-bg: #f5f5f5;
$table-head-font-size: em-calc(14);
$table-head-font-color: #222;
$table-head-font-weight: bold;
$table-head-padding: em-calc(8 10 10);

// These control the row padding and font styles
$table-row-padding: em-calc(9 10);
$table-row-font-size: em-calc(14);
$table-row-font-color: #222;
$table-line-height: em-calc(18);

// These are for controlling the display and margin of tables
$table-display: table-cell;
$table-margin-bottom: em-calc(20);


//
// Table Mixin
//
@mixin table {
  background: $table-bg;
  margin-bottom: $table-margin-bottom;
  border: $table-border-style $table-border-size $table-border-color;

  thead,
  tfoot {
    background: $table-head-bg;
    font-weight: $table-head-font-weight;

    tr {
      th,
      td {
        padding: $table-head-padding;
        font-size: $table-head-font-size;
        color: $table-head-font-color;
        text-align: $default-float;
      }
    }
  }

  tr {
    th,
    td {
      padding: $table-row-padding;
      font-size: $table-row-font-size;
      color: $table-row-font-color;
    }

    &.even,
    &.alt,
    &:nth-of-type(even) { background: $table-even-row-bg; }
  }

  thead tr th,
  tfoot tr th,
  tbody tr td,
  tr td,
  tfoot tr td { display: $table-display; line-height: $table-line-height; }
}

@if $include-html-table-classes {

  /* Tables */
  table {
    @include table;
  }

}

/* Include table rules for special markup */

table.styled {
  @include table;
  margin-top: 35px;
  margin-left: auto;
  margin-right: auto;
}

.table-controls {
  text-align: center;
}