/* ============================================================================
   Component: LivingApps Form
   ========================================================================= */

.mylivingapps-inputdata #livingapps-form {
	overflow: auto;

	.formbuilder-cascade .viewport {
		margin: 0 auto;
		z-index: 500;
	}
}