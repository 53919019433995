/* ============================================================================
   Page: Catalog Detail
   ========================================================================= */

.catalog-detail {
	.headline {
		font-size: rem-calc(24px);
	}
	.catalog-detail-app {
		.headline, .headline h1 {
			font-size: rem-calc(36px);
		}
		.headline h1 {
			text-align: left;
			padding: 0;
			margin: 0;
			border: 0;
			outline: 0;
		}
		.image {
			img {
				max-width: 100%;
			}
		}
		.tag {
			font-size: rem-calc(16px);
			font-weight: $semi-bold;
			.font-icon:before {
				font-size: 14px;
				padding-top: rem-calc(6px);
				padding-right: rem-calc(4px);
			}
		}
		.author {
			color: $color-dark;
		}
		.buttons {
			margin-top: rem-calc(12px);
		}
		.catalog-detail-app-stats {
			margin-top: rem-calc(8px);
		}
		.catalog-detail-description {
			margin-top: rem-calc(20px);
			.font-icon:before {
				font-size: rem-calc(44px);
				color: #999;
			}
		}
		.catalog-detail-preview {
			margin-top: rem-calc(20px);
			.headline {
				font-size: rem-calc(20px);
				font-weight: $regular;
			}
			.mfp-image {
				display: block;
				padding: 10px;
				text-align: center;
				background: $color-porcelain;
				img {
					max-height: 140px;
				}
			}
		}
	}
}