/* ============================================================================
   Component: Menubar
   ========================================================================= */

$menubar-height: rem-calc(68px) !default;
$menubar-background: $color-river-bed !default;
$menubar-border-bottom: 0 !default;
$menubar-item-font-size: rem-calc(20px) !default;
$menubar-item-padding-top: rem-calc(6px) !default;
$menubar-item-padding-bottom: rem-calc(6px) !default;
$menubar-item-padding-left: rem-calc(8px) !default;
$menubar-item-padding-right: rem-calc(8px) !default;
$menubar-item-margin: rem-calc(16px) 0 !default;
$menubar-item-left-margin-right: rem-calc(30px) !default;
$menubar-item-right-margin-left: rem-calc(15px) !default;
$menubar-link-color: $color-light !default;
$menubar-link-hover-color: darken($menubar-link-color, 10%) !default;
$menubar-margin: 0 0 rem-calc(30px) 0 !default;

// Menubar Crosslink styles
$menubar-crosslink-item-font-size: rem-calc(16px) !default;
$menubar-crosslink-item-padding: 0 !default;
$menubar-crosslink-item-margin: rem-calc(8px) 0 0 !default;

// Menubar Icon styles
$menubar-menu-link-font-size: rem-calc(16px) !default;
$menubar-menu-link-weight: $semi-bold !default;
$menubar-menu-link-color: $color-light !default;
$menubar-menu-icon-color: $color-light !default;
$menubar-menu-link-color-toggled: darken($menubar-menu-icon-color, 10%) !default;
$menubar-menu-icon-color-toggled: darken($menubar-menu-icon-color, 10%) !default;
$menubar-menu-icon-width: rem-calc(24px) !default;
$menubar-menu-icon-position: right !default;

// Menubar App Icon styles
$menubar-appicon-height: $menubar-height - rem-calc(10px)*2 !default;
$menubar-appicon-width: $menubar-height - rem-calc(10px)*2 !default;
$menubar-appicon-margin-top: rem-calc(10px) !default;
$menubar-appicon-margin-bottom: rem-calc(10px) !default;
$menubar-appicon-margin-left: 0 !default;
$menubar-appicon-margin-right: rem-calc(45px) !default;
$menubar-appicon-background: $color-iron-dark !default;

// Transitions and breakpoint styles
$menubar-breakpoint: $break-medium !default; // Change to 9999px for always mobile layout
$menubar-media-query: only screen and (min-width: $menubar-breakpoint) !default;

// Accessibility mixins for hiding and showing the menu dropdown items
@mixin menubar-hide-dropdown {
	// Makes an element visually hidden by default, but visible when focused.
	@include element-invisible();
	display: block;
}

@mixin menubar-show-dropdown {
	@include element-invisible-off();
	display: block;
	position: absolute !important; // Reset the position from static to absolute
}


/* Menubar */
.menubarRowWrapper {
}

.menubar-wrapper {
	position: relative;
	z-index: 1000;
	margin: $menubar-margin;
	.menubarRow {
		height: $menubar-height;
		z-index: 10;
		background: $menubar-background;
		border: $menubar-border-bottom;
	}
	.right,
	.left {
		padding: 0;
		margin: 0;
		.menubar-crosslink-item {
			position: relative;
			overflow: hidden;
			float: left;
			font-size: $menubar-crosslink-item-font-size;
			text-indent: 0;
			padding: $menubar-crosslink-item-padding;
			margin: $menubar-crosslink-item-margin;
			&:before {
				display: none;
			}
			&.here, &:hover {
				&:hover {
					border-color: $menubar-link-hover-color;
				}
			}
			a {
				font-weight: $semi-bold;
			}
		}
	}
}




.menubar {
	background: $menubar-background;
	height: $menubar-height;
	position: relative;
	overflow: hidden;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.menubar__title-area {
		position: relative;
		height: $menubar-height;
		margin: 0 10px;
	}

	&__app-icon {
		display: inline-block;
		overflow: hidden;
		height: $menubar-appicon-height;
		width: $menubar-appicon-width;
		margin-top: $menubar-appicon-margin-top;
		margin-bottom: $menubar-appicon-margin-bottom;
		margin-right: $menubar-appicon-margin-right;
		margin-left: $menubar-appicon-margin-left;
		border-radius: 4px;
		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);

		.image {
			height: 100%;
		}

		.app-icon--menubar {
			border-radius: 4px;
			background-color: $menubar-appicon-background;
		}

		// Position Left and App Icon is displayed, we need more space to the left
		+ .menubar__toggle {
			@if $menubar-menu-icon-position == left {
				#{$menubar-menu-icon-position}: $menubar-appicon-width + $menubar-appicon-margin-left + $menubar-appicon-margin-right;
			}
		}
	}

	&__toggle {
		position: absolute;
		#{$menubar-menu-icon-position}: 0;
		top: 0;

		@if $menubar-menu-icon-position == left {
			#{$menubar-menu-icon-position}: 0;
		}

		a {
			color: $menubar-link-color;
			display: block;
			font-size: $menubar-menu-link-font-size;
			font-weight: $menubar-menu-link-weight;
			// height: $topbar-height;
			// line-height: $topbar-height;
			// padding: 0 $topbar-link-padding;
			position: relative;
		}

		&.menu-icon {
			margin-top: -16px;
			top: 50%;

			a {
				@include hamburger($menubar-menu-icon-width, false, 0, 1px, 7px, $menubar-menu-icon-color, "", false);

				color: $menubar-menu-link-color;
				height: 34px;
				line-height: 33px;
				position: relative;

				@if $menubar-menu-icon-position == left {
					padding: 0 $menubar-item-padding-left 0 $menubar-item-padding-right+$menubar-menu-icon-width;
				} @else {
					padding: 0 $menubar-item-padding-left+$menubar-menu-icon-width 0 $menubar-item-padding-right;
				}
			}
		}
	}

	&.expanded {
		background: transparent;
		height: auto;

		.menubar__topbar { }

		@media screen and (-webkit-min-device-pixel-ratio:0) {
			.menubar__section {
				.menubar__item.has-dropdown.moved > .menubar__dropdown,
				.menubar__dropdown {
					clip: initial;
				}

				// This was needed as parent ul's had padding, and the clip: was allowing content to peak through
				.menubar__item.has-dropdown:not(.moved) > ul {
					padding: 0;
				}
			}
		}
    }

	&__section {
		left: 0;
		position: relative;
		width: auto;
		@include single-transition(left, 300ms, ease-out);

		ul {
			display: block;
			height: auto;
			padding: 0;
			margin: 0;
			width: 100%;
			background: $menubar-background;
		}

		ul li {
			> a {
				display: block;
				color: $menubar-link-color;
				font-size: $menubar-item-font-size;
				line-height: rem-calc(24px);
				text-decoration: none;
				padding-top: $menubar-item-padding-top;
				padding-bottom: $menubar-item-padding-bottom;
				padding-left: rem-calc(10px);
				padding-right: rem-calc(10px);
				margin: $menubar-item-margin;
				width: 100%;
				cursor: pointer;
			}

			> button { }

			&:hover > a {
				color: $menubar-link-hover-color;
			}

			&.menubar__item--inPath {
				> a { }

				&:hover > a { }
			}

			&.menubar__item--here {
				> a { }

				&:hover > a { }
			}
		}

		.menubar__item.has-dropdown {
			position: relative;

			> a { }

			&.moved {
				position: static;

				> .menubar__dropdown {
					@include menubar-show-dropdown();
					width: 100%;
				}

				> a:after {
					display: none;
				}
			}
		}

		.menubar__dropdown {
			@include menubar-hide-dropdown();
			padding: 0;
			position: absolute;
			top: 0;
			z-index: 99;
			left: 100%;

			li {
				height: auto;
				width: 100%;
				white-space: nowrap;
				margin: rem-calc(3px) 0;

				a { }

				&:hover {
					a {
					}
				}

				button, .button {
					top: 0;
				}

				&.back {
					> a {

					}
				}
			}
		}
	}

	&__item {
		&.has-dropdown {
			> a { }
			&.hover, &:hover {
				> .menubar__dropdown {
					position: absolute !important;
					display: block;
					width: auto;
					height: auto;
					overflow: visible;
					clip: auto;
				}
			}
		}
		.font-icon:before {
			font-size: 0.9em;
			line-height: inherit;
			padding-right: rem-calc(3px);
		}
		.font-icon-down:before {
			padding-right: 0;
			padding-left: rem-calc(5px);
		}
	}
}

.menubar-js-breakpoint {
	width: $menubar-breakpoint !important;
	visibility: hidden;
}
.js-generated { display: block; }


@media #{$menubar-media-query} {
	.menubar {
		//@include clearfix;
		padding: 0 30px;
		background: $menubar-background;
		overflow: visible;

		&.expanded {
			background: $menubar-background;
		}

		&__toggle { display: none; }

		.menubar__title-area {
			float: left;
			margin: 0;
		}

		&__section {
			@include single-transition(none, 0, 0);
			left: 0 !important;

			ul {
				display: inline;
				height: auto !important;
				width: auto;
				background: transparent;
				li {
					float: left;
					> a {
						padding-left: $menubar-item-padding-left;
						padding-right: $menubar-item-padding-right;
					}

					.js-generated { display: none; }
				}
			}

			li {
				&.hover, &:hover {
					> a {
						box-shadow: inset 0 -2px 0 $menubar-link-color;
					}
				}

				&.menubar__item--inPath {
					> a {
						box-shadow: inset 0 -2px 0 $menubar-link-color;
					}
					&.hover, &:hover {
						> a {
							box-shadow: inset 0 -2px 0 $menubar-link-hover-color;
						}
					}
				}

				&.menubar__item--here {
					> a {
						box-shadow: inset 0 -2px 0 $menubar-link-color;
					}
					&.hover, &:hover {
						> a {
							box-shadow: inset 0 -2px 0 $menubar-link-hover-color;
						}
					}
				}
			}

			.menubar__item.has-dropdown {
				> a {}

				&.moved {
					position: relative;
					> .dropdown {
						@include menubar-hide-dropdown();
					}
				}

				&.hover, &.not-click:hover {
					> .dropdown {
						@include menubar-show-dropdown();
					}
				}

				> a:focus + .dropdown {
					@include menubar-show-dropdown();
				}

				.menubar__dropdown .menubar__item.has-dropdown { }
			}

			.menubar__dropdown {
				left: 0;
				min-width: 100%;
				top: auto;
				padding: rem-calc(8px) 0;
				box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.25);
				background: $color-light;

				li {
					> a {
						color: #333;
						padding: rem-calc(11px) rem-calc(16px);
						margin: 0;
						box-shadow: none !important;
						@include single-transition(background);
					}

					&.hover, &:hover {
						> a {
							color: darken(#333, 10%);
							background: $color-porcelain;
						}
					}

					&.menubar__item--inPath {
						> a { }
						&.hover, &:hover {
							> a { }
						}
					}

					&.menubar__item--here {
						> a {
							color: darken(#333, 10%);
							background: $color-porcelain;
						}
						&.hover, &:hover {
							> a { }
						}
					}

					// Second Level Dropdowns
					.menubar__dropdown {
						left: 100%;
						top: 0;
					}
				}
			}

			.left {
				.menubar__item {
					margin-right: $menubar-item-left-margin-right;
				}

				.menubar__item .menubar__dropdown {
					right: auto;
					left: 0;
					left: -8px;

					.menubar__item { margin-right: 0; }

					.menubar__item .menubar__dropdown { right: 100%; }
				}
			}

			.right {
				.menubar__item {
					margin-left: $menubar-item-right-margin-left;
				}

				.menubar__item .menubar__dropdown {
					left: auto;
					right: 0;

					.menubar__item { margin-left: 0; }

					.menubar__item .menubar__dropdown { left: 100%; }
				}
			}
		}
	}
}

.menubar-form-actions {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	padding: 0 10px;
	background: $menubar-background;
}

.menubar-form-actions-buttons {
	height: 100%;
	padding-top: rem-calc(12px);
	padding-bottom: rem-calc(12px);
	.button {
		vertical-align: top;
		line-height: rem-calc(34px);
	}
}

@media #{$menubar-media-query} {
	.menubar-form-actions {
		padding: 0 30px;
	}
}