/* ============================================================================
   Page: User Owndata
   ========================================================================= */

.user-owndata {
	.headline {
		font-size: rem-calc(28px);
		line-height: rem-calc(34px);
		margin-top: rem-calc(16px);
	}
	.panel .columns:first-child {
		.headline {
			margin-top: 0;
		}
	}
	.user-owndata-contactperson {
		.row .row .columns {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}