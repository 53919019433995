/* ============================================================================
   Component: Fileupload
   ========================================================================= */

.fileupload {
	position: relative;
	img {
		width: 100%;
	}
	.overlay-content {
		text-align: center;
		position: absolute;
		width: 100%;
		top: 50%;
		left: 0;
		margin-top: -30px;
		@include single-transition(opacity);
	}
	&.exists {
		.overlay-content {
			opacity: 0;
		}
		&:hover .overlay-content {
			opacity: 1;
		}
	}
}

.fileinput-button {
	margin: 0;
	position: relative;
	overflow: hidden;
}

.fileinput-button input {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	margin: 0;
	opacity: 0;
	font-size: 200px;
	direction: ltr;
	cursor: pointer;
}

/* Fixes for IE < 8 */
@media screen\9 {
	.fileinput-button input {
		filter: alpha(opacity=0);
		font-size: 100%;
		height: 100%;
	}
}

.dropzone {
	@include single-transition(all);
}

.dropzone.hover {
	.overlay-content {
		opacity: 1;
		.button {
			@extend .button:hover;
		}
	}
}

.camera-upload {
	.overlay-content {
		top: auto;
		bottom: 0;
		margin-top: 0;
	}
	&.exists {
		.overlay-content {
			opacity: 1;
			.fileinput-button {
				@include single-transition(all);
				background: transparent;
				border: 0;
				span {
					opacity: 0;
				}
			}
		}
		&:hover .overlay-content {
			.fileinput-button {
				background: rgba(70, 83, 91, 0.7);
				span {
					opacity: 1;
				}
			}
		}
	}
	.fileinput-button {
		background: rgba(70, 83, 91, 0.7);
		border-top: 1px solid rgba(70, 83, 91, 0.1);
		border-radius: 0 0 4px 4px;
		.font-icon {
			font-size: 20px;
			float: left;
			text-shadow: 0 1px 1px rgba(0,0,0,0.4);
			color: #fff;
			padding: 4px 0px 5px 8px;
		}
		span {
			color: #fff;
			font-weight: 600;
			margin-left: 45px;
			text-align: left;
			display: block;
			padding: 5px 0;
			font-size: 14px;
		}
	}
}