/* ============================================================================
   Component: Contextmenu
   ========================================================================= */

$contextmenu-width: 220px !default;
$contextmenu-right: -180px !default;
$contextmenu-background: $color-light !default;
$contextmenu-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.25) !default;
$contextmenu-item-color: $color-primary !default;
$contextmenu-item-hover-color: $color-primary-hover !default;
$contextmenu-item-delete-color: $color-red !default;
$contextmenu-item-delete-hover-color: $color-red-dark !default;
$contextmenu-separator-padding: rem-calc(5px) 0 !default;
$contextmenu-button-color: $color-iron-dark !default;
$contextmenu-button-hover-color: $color-iron-x-dark !default;

$favorite-button-color: $color-primary !default;
$favorite-button-hover-color: $color-primary-hover !default;

.contextmenu {
	display: none;
	position: absolute;
	top: 0;
	right: $contextmenu-right;
	width: $contextmenu-width;
	padding: 15px 20px;
	margin: 0;
	background: $contextmenu-background;
	cursor: auto;
	z-index: 1010;
	border-radius: 4px;
	box-shadow: $contextmenu-box-shadow;
	@media only screen and (max-width: $break-small) {
		right: 0;
	}
}

.contextmenu-item {
	cursor: pointer;
	.font-icon:before {
		font-size: 16px;
		line-height: 24px;
		padding-right: 5px;
	}
	&.delete {
		a {
			color: $contextmenu-item-delete-color;
			&:hover {
				color: $contextmenu-item-delete-hover-color;
			}
		}
	}
	a {
		display: block;
		padding: rem-calc(5px) 0;
		color: $contextmenu-item-color;
		&:hover {
			color: $contextmenu-item-hover-color;
			text-decoration: underline;
		}
	}
	.separatorShell {
		padding: 0;
		cursor: auto;
	}
	&.contextmenu-separator {
		padding: $contextmenu-separator-padding;
	}
}

.contextmenu-button {
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 7;
	color: $contextmenu-button-color;
	-webkit-backface-visibility: hidden;
	&:hover {
		color: $contextmenu-button-hover-color;
	}
	.font-icon:before {
		padding: rem-calc(10px);
	}
}

.favorite-button {
	@extend .contextmenu-button;
	left: 0;
	right: auto;
	z-index: 5;
	.font-icon:before {
		padding: rem-calc(10px);
	}
	.font-icon-star {
		color: $favorite-button-color;
		&:hover {
			color: $favorite-button-hover-color;
		}
	}
}