/* ============================================================================
   Component: Block UI
   ========================================================================= */

$blockui-padding: 0 !default;
$blockui-margin: 0 0 0 -15% !default;
$blockui-width: 30% !default;
$blockui-top: 40% !default;
$blockui-left: 50% !default;
$blockui-color: rgb(0, 0, 0) !default;
$blockui-loading-height: 120px !default;
$blockui-overlay-background: rgba(11, 11, 11, 0.3) !default;

.blockMsg {
	padding: $blockui-padding;
	margin: $blockui-margin;
	width: $blockui-width;
	top: $blockui-top;
	left: $blockui-left;
	text-align: center;
	color: $blockui-color;
	cursor: wait;
	zoom: 1;
	.loading {
		height: $blockui-loading-height;
	}
}