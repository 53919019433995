/* ============================================================================
   Base: Icon Font
   ========================================================================= */

@at-root {
  @font-face {
    font-family: "ll_appbuilder_iconfont";
    src: url("~fonts/livingapps.eot");
    src: url("~fonts/livingapps.eot?#iefix") format("embedded-opentype"),
         url("~fonts/livingapps.woff") format("woff"),
         url("~fonts/livingapps.ttf") format("truetype"),
         url("~fonts/livingapps.svg#iconvault") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}

@mixin fonticon($icon, $position: "before", $replace: false) {
  @if $replace {
    font-size: 0;
  }
  &:#{$position} {
    @extend .font-icon-#{$icon};
    font-family: 'll_appbuilder_iconfont';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    text-decoration: none;
    vertical-align: top;
    font-size: 1em;
    @if $replace {
      font-size: 1em;
    }
    @content;
  }
}

.font-icon:before
{
  font-family: "ll_appbuilder_iconfont" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  text-decoration: none;
  vertical-align: top;
  font-size: 1.5em;
}

.font-icon-zuordnen:before {
  content: "\e910";
}
.font-icon-select-arrow:before {
  content: "\e911";
}
.font-icon-add_user:before {
  content: "\e90f";
}
.font-icon-package:before {
  content: "\e90e";
}
.font-icon-schloss_offen:before {
  content: "\e90c";
}
.font-icon-schloss_zu:before {
  content: "\e90d";
}
.font-icon-fb_geo:before {
  content: "\e90b";
}
.font-icon-fb_bildelement:before {
  content: "\e90a";
}
.font-icon-fb_haekchen:before {
  content: "\e900";
}
.font-icon-fb_mehrfachauswahl:before {
  content: "\e901";
}
.font-icon-fb_radio:before {
  content: "\e902";
}
.font-icon-fb_text:before {
  content: "\e903";
}
.font-icon-fb_upload:before {
  content: "\e904";
}
.font-icon-fb_auswahlbox:before {
  content: "\e905";
}
.font-icon-fb_button:before {
  content: "\e906";
}
.font-icon-fb_passwort:before {
  content: "\e907";
}
.font-icon-fb_textfeld_einzeilig:before {
  content: "\e908";
}
.font-icon-fb_textfeld_mehrzeilig:before {
  content: "\e909";
}
.font-icon-katalog_anwendung:before {
  content: "\e63c";
}
.font-icon-katalog_beschreibung:before {
  content: "\e63f";
}
.font-icon-katalog_zielgruppe:before {
  content: "\e640";
}
.font-icon-kamera:before {
  content: "\e63e";
}
.font-icon-sort:before {
  content: "\e63d";
}
.font-icon-home:before {
  content: "\e63b";
}
.font-icon-return:before {
  content: "\e63a";
}
.font-icon-kontext:before {
  content: "\e60d";
}
.font-icon-zauberstab:before {
  content: "\e631";
}
.font-icon-paragraph:before {
  content: "\e632";
}
.font-icon-plus:before {
  content: "\e633";
}
.font-icon-punkt:before {
  content: "\e634";
}
.font-icon-star_outline:before {
  content: "\e635";
}
.font-icon-star:before {
  content: "\e636";
}
.font-icon-unsichtbar:before {
  content: "\e637";
}
.font-icon-wf_down:before {
  content: "\e638";
}
.font-icon-wf_right:before {
  content: "\e639";
}
.font-icon-notification:before {
  content: "\e630";
}
.font-icon-undo:before {
  content: "\e602";
}
.font-icon-redo:before {
  content: "\e62f";
}
.font-icon-kopieren:before {
  content: "\e61d";
}
.font-icon-abmelden:before {
  content: "\e600";
}
.font-icon-app:before {
  content: "\e601";
}
.font-icon-arbeitsaufgaben:before {
  content: "\e603";
}
.font-icon-auswertung:before {
  content: "\e604";
}
.font-icon-autor:before {
  content: "\e605";
}
.font-icon-bearbeiten:before {
  content: "\e606";
}
.font-icon-check:before {
  content: "\e607";
}
.font-icon-daten_verbinden:before {
  content: "\e608";
}
.font-icon-datenmanagement_konfig:before {
  content: "\e609";
}
.font-icon-down:before {
  content: "\e60a";
}
.font-icon-drag:before {
  content: "\e60b";
}
.font-icon-dynamisieren:before {
  content: "\e60c";
}
.font-icon-einbinden:before {
  content: "\e60e";
}
.font-icon-excel:before {
  content: "\e60f";
}
.font-icon-excelx:before {
  content: "\e610";
}
.font-icon-flexibel:before {
  content: "\e611";
}
.font-icon-freigeben:before {
  content: "\e612";
}
.font-icon-geld:before {
  content: "\e613";
}
.font-icon-gluehbirne:before {
  content: "\e614";
}
.font-icon-hilfe:before {
  content: "\e615";
}
.font-icon-hinweis:before {
  content: "\e616";
}
.font-icon-import_export:before {
  content: "\e617";
}
.font-icon-info:before {
  content: "\e618";
}
.font-icon-kalender:before {
  content: "\e619";
}
.font-icon-katalog_small:before {
  content: "\e61a";
}
.font-icon-katalog_veroeffentlichen:before {
  content: "\e61b";
}
.font-icon-katalog:before {
  content: "\e61c";
}
.font-icon-left:before {
  content: "\e61e";
}
.font-icon-loeschen:before {
  content: "\e61f";
}
.font-icon-menu:before {
  content: "\e620";
}
.font-icon-muell:before {
  content: "\e621";
}
.font-icon-neue_webapp:before {
  content: "\e622";
}
.font-icon-optionen:before {
  content: "\e623";
}
.font-icon-right:before {
  content: "\e624";
}
.font-icon-sammeln:before {
  content: "\e625";
}
.font-icon-suche:before {
  content: "\e626";
}
.font-icon-support:before {
  content: "\e627";
}
.font-icon-tags:before {
  content: "\e628";
}
.font-icon-uhr:before {
  content: "\e629";
}
.font-icon-umfragen:before {
  content: "\e62a";
}
.font-icon-up:before {
  content: "\e62b";
}
.font-icon-verifizieren:before {
  content: "\e62c";
}
.font-icon-vorschau:before {
  content: "\e62d";
}
.font-icon-formular:before {
  content: "\e62e";
}
