/* ============================================================================
   Component: Datamanagement Table
   ========================================================================= */

$datamanagement-table-border: $color-mercury !default;
$datamanagement-table-border-radius: 4px !default;
$datamanagement-table-row-odd-background: $color-athens-gray !default;

.dataManagementTable, .internalFieldsTable
{
	width: 100%;
	border-collapse: separate;
	border: 1px solid $datamanagement-table-border;
	border-radius: 4px;
	td, th {
		border-top: 1px solid $datamanagement-table-border;
		border-left: 1px solid $datamanagement-table-border;
	}
	th {
		width: 17%;
		font-size: emCalc(24px);
		font-weight: $extra-light;
		color: $color-primary;
		text-align: left;
		padding: emCalc(5px) emCalc(16px);
		border-top: none;
	}
	.col1 {
		width: 47%;
	}
	.highlight {
		color: #fff;
		background: $color-primary;
	}
	.formCheckbox {
		width: 1%;
		text-align: center;
	}
	td {
		padding: emCalc(2px) emCalc(24px);
		&:nth-child(1) {
			font-weight: $semi-bold;
		}
	}
	tr:nth-child(odd) td {
		background: $datamanagement-table-row-odd-background;
	}
	th:first-child {
		border-top-left-radius: $datamanagement-table-border-radius;
	}
	th:last-child {
		border-top-right-radius: $datamanagement-table-border-radius;
	}
	tr:last-child td:first-child {
		border-bottom-left-radius: $datamanagement-table-border-radius;
	}
	tr:last-child td:last-child {
		border-bottom-right-radius: $datamanagement-table-border-radius;
	}
	td:first-child, th:first-child {
		border-left: 0;
	}
	.abideError {
		font-size: emCalc(14px);
		padding-top: emCalc(5px, 14px);
	}
}

.internalFieldsTable
{
	td {
		padding: emCalc(10px) emCalc(11px);
	}
	.col1 {
		width: 5%;
	}
	.col2 {
		width: 42%;
	}
	.custom & input {
		font-size: emCalc(14px);
		padding: emCalc(2px, 14px) emCalc(10px, 14px);
	}
	.custom & .custom.dropdown {
		height: emCalc(26px);
		margin-bottom: 0;
		.current {
			font-size: emCalc(14px);
			line-height: emCalc(22px, 14px);
		}
		.selector {
			width: emCalc(22px);
			height: emCalc(22px);
			&:after {
				font-size: 14px;
				margin-top: -7px;
				left: 3px;
			}
		}
	}
	.internal-field-delete
	{
		text-align: center;
		.font-icon {
			@include single-transition(all);
			color: $color-grey;
			cursor: pointer;
			&:hover {
				color: darken($color-grey, 10%);
			}
			&:before {
				font-size: 18px;
				padding-top: 3px;
			}
		}
	}
}