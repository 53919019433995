/* ============================================================================
   Page: Login Page
   ========================================================================= */

$login-form-background: $color-athens-gray !default;
$login-form-border-radius: 4px !default;
$login-form-box-shadow: 0 2px 0 darken($login-form-background, 10%) !default;

.extLoginErrShell .itemDes1 {
	display: inline;
	color: $color-red;
	font-weight: $semi-bold;
}

.loginForm {
	.formLinkShell {
		display: none;
	}
	.formDesc {
		display: none;
	}
}

.login-form {
	position: relative;
	overflow: hidden;
	padding: rem-calc(30px);
	background: $login-form-background;
	border-radius: $login-form-border-radius;
	box-shadow: $login-form-box-shadow;
}

.pageStyle_login {
	@extend %background-light;

	.contentElements {
		padding-top: 5%;
		@media screen and (max-width: $break-small) {
			padding-top: rem-calc(10px);
		}
	}

	.custom {
		input[type="text"],
		input[type="email"],
		input[type="password"] {
			font-size: rem-calc(18px);
			border-radius: 4px;
		}
		input[type="submit"] {
			margin-top: rem-calc(25px);
		}
	}

	hr {
		height: 2px;
		width: 20%;
		margin-left: auto;
		margin-right: auto;
	}
}