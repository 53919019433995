/* ============================================================================
   Page: Registration Confirm Page
   ========================================================================= */

.pageStyle_registerconfirm {
	@extend %background-light;

	.contentElements {
		padding-top: 10%;
	}

	.headline1 {
		@extend .headline-account;
		padding-top: 0;
	}
}