/* ============================================================================
   Component: Cookie Notice
   ========================================================================= */

$cookienotice-popup-color: $color-light !default;
$cookienotice-popup-background: $color-river-bed !default;
$cookienotice-button-color: $color-light !default;
$cookienotice-button-background: $color-primary !default;
$cookienotice-button-background-hover: $color-primary-hover !default;

.cc-window {
	z-index: 10000 !important;
	color: $cookienotice-popup-color;
	background-color: $cookienotice-popup-background;
}

.cc-btn {
	color: $cookienotice-button-color;
	background-color: $cookienotice-button-background;
	border-color: transparent;

	&:hover,
	&:focus {
		color: $cookienotice-button-color;
		background-color: $cookienotice-button-background-hover;
	}
}

.cc-link,
.cc-link:active,
.cc-link:visited {
	color: $cookienotice-popup-color;
}