/* >> Anpassungen des Logos und Bilder im Kopfbereich Version: rel-1-1-0 */
	/* Tabelle fuer Kopfbilder */
	.headerImg {
	}

	.headerImg .wrapper {
		position: relative;
		overflow: visible;
		*zoom: 1;
	}

	.hImg, .hImg1, .hImg2 {
		display: none;
	}

	/* Verhalten des 1 Kopfbereichsbild */
	.hImg {
	}

	/* Verhalten des 2 Kopfbereichsbild */
	.hImg1 {
	}

	/* Verhalten des 3 Kopfbereichsbild */
	.hImg2 {
	}

	@media only screen and (max-width: $break-medium) {
		.headerImg {
			min-height: 54px;
			line-height: 53px;
		}
	}
/* << */