/* ============================================================================
   Page: Contactpage
   ========================================================================= */

.pageStyle_contactForm {
	.errorMsg_input {
		text-align: center;
		font-weight: $semi-bold;
		color: $color-red;
	}
	.defaultErrorList {
		.errorMsg_send {
			text-align: center;
		}

	}
}